import * as React from 'react'
import styled from '@emotion/styled'
import Container from '../components/Container'
import MainLayout from './MainLayout'

const LayoutContainer = styled(Container)`
  padding-top: 10%;
`

const NarrowLayout: React.FC = ({ children }) => {
  return (
    <MainLayout>
      <LayoutContainer>{children}</LayoutContainer>
    </MainLayout>
  )
}

export default NarrowLayout

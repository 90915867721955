import * as React from 'react'
import { Link } from 'gatsby'
import Container from '../components/Container'
import NarrowLayout from '../layouts/NarrowLayout'
import styled from '@emotion/styled'
import { colors } from '../styles/variables'
const NotFoundWrapper = styled.div`
  margin-bottom: 10vh;
  h1 {
    margin-top: 10vh;
    font-size: 4em;
    -webkit-text-stroke: 2px white;
    -webkit-text-fill-color: ${colors.black};
    text-shadow: 3px 3px 4px ${colors.primary};
  }
  p {
    margin-top: 2em;
    line-height: 1.8em;
    font-size: 1.1em;
  }
`
const NotFoundPage = () => (
  <NarrowLayout>
    <Container>
      <NotFoundWrapper>
        <h1>Strony nie znaleziono</h1>
        <p>
          You've hit the void. <Link to="/">Go back.</Link>
        </p>
      </NotFoundWrapper>
    </Container>
  </NarrowLayout>
)

export default NotFoundPage
